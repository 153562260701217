@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl font-black;
  }
  h2 {
    @apply text-2xl font-bold z-50 !text-black dark:!text-white;
  }

  body {
    @apply dark:bg-black dark:border-neutral-800;
  }
}

.dark *,
.dark *::before,
.dark *::after {
  /**
  * Set all text to white
  no clue why this is required or whats happened to the compilation of tailwind stuff. ..
  */
  border-color: #202020 !important;
  @apply !border-neutral-800;
}

@layer utilities {
  .black-heading {
    @apply font-black uppercase text-3xl lg:text-5xl !leading-[1.4] sm:!leading-[1.4] md:!leading-[1.4] lg:!leading-[1.4];
  }
}

img {
  height: auto !important;
  width: auto;
}

#hubspot-messages-iframe-container {
  color-scheme: none;
}

@media not all and (min-resolution: 0.001dpcm) {
  img[loading='lazy'] {
    clip-path: inset(0.5px);
  }
}

@media (min-width: 600px) {
  .wp-block-columns {
    grid-template-columns: repeat(12, 1fr) !important;
  }

  .wp-block-column {
    grid-column-start: auto;
    grid-column-end: span 6;
  }

  .wp-block-column:only-child {
    grid-column-end: span 12;
  }
}

@media (min-width: 900px) {
  /* Three column layout */
  .wp-block-column:not([style*='flex-basis']):nth-last-child(3):first-child,
  .wp-block-column:not([style*='flex-basis']):nth-last-child(3):first-child
    ~ .wp-block-column {
    grid-column-end: span 4;
  }

  /* "25 / 50 / 25" layout */
  .wp-block-column[style*='25%'] {
    grid-column-end: span 3;
  }
  .wp-block-column[style*='50%'] {
    grid-column-end: span 6;
  }

  /* "30 / 70" and "70 / 30" layouts */
  .wp-block-column[style*='33.33%'] {
    grid-column-end: span 4;
  }
  .wp-block-column[style*='66.66%'] {
    grid-column-end: span 8;
  }
}

@media screen and (max-width: 62em) {
  /*
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }


  table td::before {
  
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }


  table td:last-child {
    border-bottom: 0;
  }
  */
}

font {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.scrollarea::-webkit-scrollbar-track {
  background: transparent;
}
.scrollarea::-webkit-scrollbar {
  width: 4px;
}
.scrollarea::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 20px;
}
